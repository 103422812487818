<template>
  <div>
    <b-container>
      <app-page-header title="Video Posts">
        <!-- <b-form>
          <b-input v-model="query" />
        </b-form> -->
        <b-button @click="openAddModal()" variant="primary">Add Slot</b-button>
      </app-page-header>
      <template>

        <!-- Edit Model -->
        <vue-good-table v-if="!finding" :columns="tableFields" :rows="data">
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'slot'">
              {{ props.row.slot }}
            </span>
            <span v-if="props.column.field == 'zoneId'">
              {{ props.row.zoneId }}
            </span>
            <span v-if="props.column.field == 'title'">
              {{ props.row.title }}
            </span>
            <span v-if="props.column.field == 'status'">
              {{ props.row.status }}
            </span>
            <span v-if="props.column.field == 'action'" class="action-box">
              <b-button @click="openEditModal(props.row)" variant="primary">Edit</b-button>
              <b-button @click="openDeleteModal(props.row)" variant="danger">Delete</b-button>
            </span>
          </template>
        </vue-good-table>
        <b-modal ref="AddEditFeaturedPostModal" @ok="AddUpdateFeaturedPost()" @cancel="closeEditModal()">
          <template #modal-title>
            <h4>{{ formTitle }}</h4>
          </template>

          <b-form-group label="Slot">
            <b-form-input v-model="draftItem.slot"></b-form-input>
          </b-form-group>
          <b-form-group label="Post Id">
            <b-form-input v-model="draftItem.zoneId"></b-form-input>
          </b-form-group>
          <!-- <b-form-group label="Title">
            <b-form-input v-model="draftItem.title"></b-form-input>
          </b-form-group> -->

          <b-form-group class="datetime-input" label="Display Start">
            <b-form-datepicker v-model="draftItem.display_start_date" :time="true"></b-form-datepicker>
            <b-form-timepicker v-model="draftItem.display_start_time" :time="true"></b-form-timepicker>
          </b-form-group>
          <b-form-group class="datetime-input" label="Display End">
            <b-form-datepicker v-model="draftItem.display_end_date" :time="true"></b-form-datepicker>
            <b-form-timepicker v-model="draftItem.display_end_time" :time="true"></b-form-timepicker>
          </b-form-group>

          <b-form-group label="Active" v-if="formType !== 'add'">
            <b-form-checkbox v-model="draftItem.status" switch></b-form-checkbox>
          </b-form-group>
          <div class="warning">{{ warningMessage }}
          </div>
        </b-modal>

        <b-modal ref="warningModal" @ok="closeWarningModal()">
          <template #modal-title>
            <h4>Alert</h4>
          </template>
          <template #default>
            <p>
              {{ warningMessage }}. Please check the slot and status and try again.
            </p>
          </template>
        </b-modal>

        <b-modal ref="deleteModal" @ok="deleteItem()" @cancel="closeDeleteModal()">
          <template #modal-title>
            <h4>Delete Confirmation</h4>
          </template>
          <template #default>
            <p>Are you sure you want to delete this item?</p>
          </template>
        </b-modal>
      </template>
    </b-container>
  </div>
</template>
<script>
import AppPageHeader from '@/components/PageHeader.vue';
import GetVideoPostSlots from '@/gql/channels/GetVideoPostSlots.gql';
import UpdateVideoPostSlots from '@/gql/channels/UpdateVideoPostSlots.gql';
import AddVideoPostSlots from '@/gql/channels/AddVideoPostSlots.gql';
import DeleteVideoPostSlot from '@/gql/channels/DeleteVideoPostSlot.gql';
import $utilities from '@/services/utilities';

export default {
  computed: {
    formTitle() {
      return this.draftItem.slot === '' ? 'Add Video Slot' : 'Update Video Slot';
    },
  },

  components: {
    AppPageHeader,
  },
  data() {
    return {
      finding: false,
      warningMessage: '',
      showWarning: false,
      data: [],
      query: '',
      isEditModalOpen: false,
      draftItem: {
        uuid: '',
        slot: '',
        zoneId: '',
        status: true,
        title: '',
        display_start_date: '2023-01-01',
        display_start_time: '00:00:00',
        display_end_date: new Date(),
        display_end_time: '2023-12-31',
      },
      tableFields: [
        {
          label: 'Slot',
          field: 'slot',
        },
        {
          label: 'Post Id',
          field: 'zoneId',
        },
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Active',
          field: 'status',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      formType: 'edit',
    };
  },
  mounted() {
    this.getVideosSlots();
  },
  watch: {
    'draftItem.display_start_date': 'updateWarningMessage',
    'draftItem.display_end_date': 'updateWarningMessage',
    'draftItem.display_start_time': 'updateWarningMessage',
    'draftItem.display_end_time': 'updateWarningMessage',
    'draftItem.slot': 'updateWarningMessage',
    'draftItem.status': 'updateWarningMessage',
  },
  methods: {
    updateWarningMessage() {
      const draftStartDateTime = $utilities.concatDateAndTime(this.draftItem.display_start_date, this.draftItem.display_start_time);
      const draftEndDateTime = $utilities.concatDateAndTime(this.draftItem.display_end_date, this.draftItem.display_end_time);

      // Check if start time is greater than or equal to end time
      if (draftStartDateTime >= draftEndDateTime) {
        this.showWarning = true;
        this.warningMessage = 'Start time cannot be greater than or equal to end time';
      } else {
        const currentDateUTC = new Date().toISOString();
        // Check if start or end date/time is before the current UTC time
        if (new Date(draftStartDateTime) < new Date(currentDateUTC) || new Date(draftEndDateTime) < new Date(currentDateUTC)) {
          this.showWarning = true;
          this.warningMessage = 'Start or end date and time cannot be before the current date and time';
        } else {
          // Check for slot conflicts with existing data
          let found = false;
          /* eslint-disable-next-line no-plusplus */
          for (let i = 0; i < this.data.length; i++) {
            const currentItem = this.data[i];
            const currentItemStartDateTime = $utilities.concatDateAndTime($utilities.getOnlyDate(currentItem.display_start), $utilities.getOnlyTime(currentItem.display_start));
            const currentItemEndDateTime = $utilities.concatDateAndTime($utilities.getOnlyDate(currentItem.display_end), $utilities.getOnlyTime(currentItem.display_end));

            // Check if the current item's slot and status match the draftItem's slot and status
            if (
              currentItem.status === this.draftItem.status
                && currentItem.slot === this.draftItem.slot
                && currentItem.uuid !== this.draftItem.uuid
                && (
                  (draftStartDateTime >= currentItemStartDateTime && draftStartDateTime <= currentItemEndDateTime)
                  || (draftEndDateTime >= currentItemStartDateTime && draftEndDateTime <= currentItemEndDateTime)
                )
            ) {
              // If match found, show warning and exit the loop
              found = true;
              this.showWarning = true;
              this.warningMessage = 'Slot already exists with the same status.';
              break;
            }
          }

          if (!found) {
            this.showWarning = false;
            this.warningMessage = '';
          }
        }
      }
    },

    getWarningMessage() {
      const currentDateUTC = new Date().toISOString();
      const draftStartDateTime = $utilities.concatDateAndTime(this.draftItem.display_start_date, this.draftItem.display_start_time);
      const draftEndDateTime = $utilities.concatDateAndTime(this.draftItem.display_end_date, this.draftItem.display_end_time);
      if (
        $utilities.concatDateAndTime(this.draftItem.display_start_date, this.draftItem.display_start_time) >= $utilities.concatDateAndTime(this.draftItem.display_end_date, this.draftItem.display_end_time)
      ) {
        this.showWarning = true;
        this.warningMessage = 'Start time cannot be greater than or equal to end time';
      } else if (new Date(this.draftItem.display_start_date) < new Date(currentDateUTC) || new Date(this.draftItem.display_end_date) < new Date(currentDateUTC)) {
        this.showWarning = true;
        this.warningMessage = 'Start or end date and cannot be before the current date and time';
      } else {
        let found = false;
        /* eslint-disable-next-line no-plusplus */
        for (let i = 0; i < this.data.length; i++) {
          const currentItem = this.data[i];
          const currentItemStartDateTime = $utilities.concatDateAndTime($utilities.getOnlyDate(currentItem.display_start), $utilities.getOnlyTime(currentItem.display_start));
          const currentItemEndDateTime = $utilities.concatDateAndTime($utilities.getOnlyDate(currentItem.display_end), $utilities.getOnlyTime(currentItem.display_end));
          // Check if the current item's slot and status match the draftItem's slot and status
          if (
            currentItem.status === this.draftItem.status
            && currentItem.slot === this.draftItem.slot
            && currentItem.uuid !== this.draftItem.uuid
            && (
              (draftStartDateTime >= currentItemStartDateTime && draftStartDateTime <= currentItemEndDateTime)
              || (draftEndDateTime >= currentItemStartDateTime && draftEndDateTime <= currentItemEndDateTime)
            )
          ) {
            // If match found, show warning and exit the loop
            found = true;
            this.showWarning = true;
            this.warningMessage = 'Slot already exists with the same status.';
            break;
          }
        }
        if (!found) {
          this.showWarning = false;
          this.warningMessage = '';
        }
      }
    },
    openDeleteModal(item) {
      this.itemToDelete = item;
      this.$refs.deleteModal.show();
    },

    opeWwarningModal() {
      this.$refs.warningModal.show();
    },

    async deleteItem() {
      const itemId = this.itemToDelete.uuid; // Adjust according to your data structure
      try {
        await this.$apollo.mutate({
          mutation: DeleteVideoPostSlot,
          variables: { id: itemId },
        });
        await this.getVideosSlots();
      } catch (error) {
         console.log(error); // eslint-disable-line
      }
      // Close the delete modal
      this.closeDeleteModal();
    },

    closeDeleteModal() {
      this.$refs.deleteModal.hide();
    },

    closeWarningModal() {
      this.$refs.warningModal.hide();
    },

    async getVideosSlots() {
      try {
        this.finding = true;
        const resp = await this.$apollo.query({
          query: GetVideoPostSlots,
          variables: {},
        });
        this.finding = false;
        this.data = resp.data.get_video_post_slots;
      } catch (err) {
        this.finding = false;
      }
    },

    openAddModal() {
      const date = new Date().getDate();
      const month = new Date().getMonth() + 1;
      const year = new Date().getFullYear();
      const today = `${year}-${month < 10 ? `0${month}` : month}-${date < 10 ? `0${date}` : date}`;
      const utcTime = new Date().toLocaleTimeString('en-US', {
        hour12: false,
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZone: 'UTC',
      });
      this.draftItem = {
        uuid: '',
        slot: '',
        zoneId: '',
        status: true,
        title: '',
        display_start_date: today,
        display_start_time: utcTime,
        display_end_date: '2024-12-31',
        display_end_time: '23:59:00',
      };
      this.formType = 'add';
      this.$refs.AddEditFeaturedPostModal.show();
      this.showWarning = false;
      this.warningMessage = '';
    },

    openEditModal(item) {
      this.formType = 'edit';
      this.draftItem.uuid = item.uuid;
      this.draftItem.slot = item.slot;
      this.draftItem.zoneId = item.zoneId;
      this.draftItem.status = item.status;
      this.draftItem.title = item.title;
      this.draftItem.display_start_date = $utilities.getOnlyDate(item.display_start);
      this.draftItem.display_start_time = $utilities.getOnlyTime(item.display_start);
      this.draftItem.display_end_date = $utilities.getOnlyDate(item.display_end);
      this.draftItem.display_end_time = $utilities.getOnlyTime(item.display_end);
      this.$refs.AddEditFeaturedPostModal.show();
      this.showWarning = false;
      this.warningMessage = '';
      this.getWarningMessage();
    },
    async AddUpdateFeaturedPost() {
      if (this.warningMessage !== null) {
        if (this.warningMessage !== '') {
          this.$refs.warningModal.show();
        } else {
          const variables = {
            slot: parseFloat(this.draftItem.slot),
            zoneId: this.draftItem.zoneId?.toString(),
            status: `${this.draftItem.status}`,
            title: this.draftItem.title, // added this line
            display_start: $utilities.concatDateAndTime(this.draftItem.display_start_date, this.draftItem.display_start_time),
            display_end: $utilities.concatDateAndTime(this.draftItem.display_end_date, this.draftItem.display_end_time),
          };
          if (this.formType === 'add') {
            await this.$apollo.mutate({
              mutation: AddVideoPostSlots,
              variables,
            });
          } else if (this.formType === 'edit') {
            variables.uuid = this.draftItem.uuid;
            await this.$apollo.mutate({
              mutation: UpdateVideoPostSlots,
              variables,
            });
          }
          await this.getVideosSlots();
          this.closeEditModal();
        }
      }
    },
    closeEditModal() {
      this.isEditModalOpen = false;
    },
  },
};
</script>

<style>
.datetime-input > div:first-of-type {
  display: flex;
  gap: 10px;
}
.action-box {
  display: flex;
  gap: 10px;
}
.warning {
  color: red;
  font-size: 16px;
  margin-top: 5px;
}
</style>
