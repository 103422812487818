import Vue from 'vue';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import VueApollo from 'vue-apollo';
import VueMoment from 'vue-moment';
import apolloClient from '@/services/apollo';
// import infiniteScroll from 'vue-infinite-scroll';
// import TextareaAutosize from 'vue-textarea-autosize';
import moment from 'moment';

// Sentry code required files. Has to be before app.vue import.
import * as Sentry from '@sentry/vue';

import VueGoodTablePlugin from 'vue-good-table';
import App from './App.vue';
import router from './router';

import 'cs-components';
import 'cs-components/dist/csuite.css';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

// import the styles
import 'vue-good-table/dist/vue-good-table.css';

import './style.css';
// import './assets/icons/css/styles.css';

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueApollo);
Vue.use(VueMoment);
Vue.use(VueGoodTablePlugin);
// Vue.use(infiniteScroll);
// Vue.use(TextareaAutosize);

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});
Vue.filter('formatDate', (value) => {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY hh:mm');
  } return value;
});
Vue.directive('router-link', {
  inserted(el, binding, vnode) {
    el.addEventListener('click', () => {
      vnode.context.$router.push(binding.value);
    });
  },
});

Sentry.init({
  Vue,
  dsn: 'https://97bdf9d3bdc345fcad0a64cc1ef9ef6d@o4505397740437504.ingest.sentry.io/4505399318151168',
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

new Vue({
  apolloProvider,
  router,
  render: (h) => h(App),
}).$mount('#app');
