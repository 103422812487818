<template>
  <div>
    <b-container>
      <app-page-header title="Suspended Users">
        <b-input v-model="query" placeholder="Search suspended users" @keyup.enter="findSuspendedUsers()" />
      </app-page-header>
      <!-- <b-table :items="displayUsers" :fields="fields" @row-clicked="viewUser" :busy="$apollo.queries.users.loading" hover  show-empty>
        <template #table-busy>
          <b-spinner />
        </template>
        <template #empty>
          No matching users
        </template>
      </b-table> -->
      <b-spinner v-if="finding" />
      <vue-good-table
        v-if="users.length"
        :columns="fields"
        :rows="users">
      <div slot="emptystate">
        No matching users
      </div>
    </vue-good-table>
    </b-container>
  </div>
</template>
<script>
import AppPageHeader from '@/components/PageHeader.vue';
import FindSuspendedUsers from '@/gql/suspendeds/FindSuspendedUsers.gql';

export default {
  components: {
    AppPageHeader,
  },
  data() {
    return {
      finding: false,
      users: [],
      query: '',
      fields: [
        {
          field: (item) => (item.user && item.user.username),
          label: 'Username',
        },
        // {
        //   field: (item) => (item.user && item.user.email),
        //   label: 'Email',
        // },
        {
          field: (value) => (this.$moment(value).format('LLL')),
          label: 'Suspension Date',
        },
        {
          field: (item) => (item.admin ? item.admin.displayname : 'Unknown'),
          label: 'Suspended by',
        },
        {
          field: 'reason',
          label: 'Reason',
        },
      ],
    };
  },
  computed: {
    displayUsers() {
      return this.users && this.users.filter((u) => (!!u.user));
    },
  },
  mounted() {
    this.findSuspendedUsers();
  },
  methods: {
    async findSuspendedUsers() {
      try {
        this.finding = true;
        const resp = await this.$apollo.query({
          query: FindSuspendedUsers,
          variables: {
            query: this.query,
          },
        });
        this.finding = false;
        this.users = resp.data.users;
      } catch (err) {
        this.finding = false;
        console.log(err);
      }
    },
    viewUser(item) {
      this.$router.push({ name: 'UserDetail', params: { id: item.row.userId } });
    },
  },

};
</script>
