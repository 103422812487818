import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '@/views/Login.vue';
import Home from '@/views/Home.vue';
import LoggedInContainer from '@/views/LoggedInContainer.vue';

import UserList from '@/views/users/UserList.vue';
import UserDetail from '@/views/users/UserDetail.vue';
import RecentUserList from '@/views/users/RecentUserList.vue';

import SuspendedUserList from '@/views/SuspendedUserList.vue';
import Verification from '@/views/Verification.vue';

import RestrictedUrlList from '@/views/RestrictedUrlList.vue';
import RestrictedWordList from '@/views/RestrictedWordList.vue';
import RestrictedEmailList from '@/views/RestrictedEmailList.vue';
import AllowedEmailList from '@/views/AllowedEmailList.vue';

import Moderation from '@/views/Moderation.vue';
import Channels from '@/views/Channels.vue';
import ManageChannels from '@/views/ManageChannels.vue';

import FeaturedPosts from '@/views/FeaturedPosts.vue';
import VideoPosts from '@/views/VideoPosts.vue';
import TranscodingReport from '@/views/TranscodingReport.vue';

import UserReports from '@/views/reports/UserReports.vue';
import UserReportsWizard from '@/views/reports/UserReportsWizard.vue';
import PostReports from '@/views/reports/PostReports.vue';

import MeetingEarnings from '@/views/meetings/MeetingEarnings.vue';
import MeetingLivestream from '@/views/meetings/MeetingLivestream.vue';

import Notifications from '@/views/Notifications.vue';
import NotificationDetail from '@/views/notifications/NotificationDetail.vue';

import SponsoredAreas from '@/views/SponsoredAreas.vue';
import AdSlots from '@/views/AdSlots.vue';

import $auth from '@/services/auth';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    name: 'LoggedInContainer',
    component: LoggedInContainer,
    path: '',
    children: [
      {
        path: '/',
        name: 'Home',
        component: Home,
      },
      {
        path: '/users',
        name: 'UserList',
        component: UserList,
      },
      {
        path: '/recentusers',
        name: 'RecentUserList',
        component: RecentUserList,
      },
      {
        path: '/users/:id',
        name: 'UserDetail',
        component: UserDetail,
        props: true,
      },
      {
        path: '/restrictedurls',
        name: 'RestrictedUrlList',
        component: RestrictedUrlList,
      },
      {
        path: '/restrictedemails',
        name: 'RestrictedEmailList',
        component: RestrictedEmailList,
      },
      {
        path: '/allowedemails',
        name: 'AllowedEmailList',
        component: AllowedEmailList,
      },
      {
        path: '/restrictedwords',
        name: 'RestrictedWordList',
        component: RestrictedWordList,
      },
      {
        path: '/suspendedusers',
        name: 'SuspendedUserList',
        component: SuspendedUserList,
      },
      {
        path: '/verification',
        name: 'Verification',
        component: Verification,
      },
      {
        path: '/moderation',
        name: 'Moderation',
        component: Moderation,
      },
      {
        path: '/notifications',
        name: 'Notifications',
        component: Notifications,
      },
      {
        path: '/notifications/:id',
        name: 'NotificationDetail',
        component: NotificationDetail,
        props: true,
      },
      {
        path: '/userreports',
        name: 'UserReports',
        component: UserReports,
      },
      {
        path: '/userreportswizard',
        name: 'UserReportsWizard',
        component: UserReportsWizard,
      },
      {
        path: '/postreports',
        name: 'PostReports',
        component: PostReports,
      },
      {
        path: '/channels',
        name: 'Channels',
        component: Channels,
      },
      {
        path: '/managechannels',
        name: 'ManageChannels',
        component: ManageChannels,
      },
      {
        path: '/featured-posts',
        name: 'FeaturedPosts',
        component: FeaturedPosts,
      },
      {
        path: '/video-posts',
        name: 'VideoPosts',
        component: VideoPosts,
      },
      {
        path: '/ad-slots',
        name: 'AdSlots',
        component: AdSlots,
      },
      {
        path: '/sponsored-areas',
        name: 'SponsoredAreas',
        component: SponsoredAreas,
      },
      {
        path: '/transcoding',
        name: 'TranscodingReport',
        component: TranscodingReport,
      },
      {
        path: '/meetingearnings',
        name: 'MeetingEarnings',
        component: MeetingEarnings,
      },
      {
        path: '/meetinglivestream',
        name: 'MeetingLivestream',
        component: MeetingLivestream,
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach(async (to, from, next) => {
  const isLoggedin = await $auth.isLoggedIn();
  if (isLoggedin && to.fullPath.includes('/login')) {
    // Logged in but trying to access an onboarding screen
    next('/');
  } else if (!isLoggedin && !to.fullPath.includes('/login')) {
    // Logged out but trying to access an app screen
    next('/login');
  } else {
    next();
  }
});

export default router;
