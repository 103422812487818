<template>
  <div>
    <b-container>
      <app-page-header title="Live Channels">
        <b-form>
          <b-input v-model="query" />
        </b-form>
      </app-page-header>
      <template>
        <b-table ref="table" hover :items="users" :sort-desc="sortCreatedAt" :sort-by="sortBy" :fields="fields" @row-clicked="select" :filter="query" show-empty :busy="$apollo.queries.users.loading">
          <template #table-busy>
            <b-spinner />
          </template>
          <template #head(createdAt)="data">
          <div @click="onSort()">
            {{ data.label }}
          </div>
        </template>
        <template #head(isLiveStreaming)="data">
          <div @click="onLiveSort()">
            {{ data.label }}
          </div>
        </template>
        <template #cell(isLiveStreaming)="data">
            <span :class="data.item.isLiveStreaming ? 'live' : ''">{{ data.item.isLiveStreaming ? 'Live' : '' }}</span>
          </template>
          <template #head(channelTrueNews)>
            <span>True News</span>
          </template>
          <template #cell(channelTrueNews)="data">
            <span>{{ data.item.channelTrueNews ? 'Yes' : 'No' }}</span>
          </template>
          <template #empty>No live channels</template>
          <template #emptyfiltered>No matching live channels</template>
        </b-table>
      </template>
    </b-container>
  </div>
</template>
<script>
import AppPageHeader from '@/components/PageHeader.vue';
import ListChannels from '@/gql/channels/ListChannels.gql';

export default {
  components: {
    AppPageHeader,
  },
  apollo: {
    users: {
      query: ListChannels,
    },
  },
  data() {
    return {
      sortBy: null,
      sortCreatedAt: false,
      query: '',
      fields: [
        'username',
        'channelName',
        'channelTrueNews',
        'channelNumber',
        {
          key: 'isLiveStreaming',
          sortable: true,
        },
        {
          key: 'lastLiveDateTime',
          label: 'Recent Live',
          formatter: (value) => (value ? this.$moment(value).format('LLL') : ''),
        },
        {
          key: 'createdAt',
          formatter: (value) => (this.$moment(value).format('LLL')),
          sortable: true,
        },
        'liveStreamKey',
      ],
    };
  },
  methods: {
    select(row) {
      this.$router.push({
        name: 'UserDetail',
        params: row,
      });
    },
    onLiveSort() {
      if (this.sortCreatedAt === false) {
        this.sortBy = 'isLiveStreaming';
        this.sortCreatedAt = true;
      } else {
        this.sortBy = 'isLiveStreaming';
        this.sortCreatedAt = false;
      }
    },
    onSort() {
      if (this.sortCreatedAt === false) {
        this.sortBy = 'createdAt';
        this.sortCreatedAt = true;
      } else {
        this.sortBy = 'createdAt';
        this.sortCreatedAt = false;
      }
    },
  },
};
</script>
