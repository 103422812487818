import axios from 'axios';
import jsonwebtoken from 'jsonwebtoken';

const user = {};
let userId = null;

const getUser = async () => user;

const setHeader = (token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

const isLoggedIn = async () => {
  if (!localStorage.access_token) return false;
  const userdata = await getUser();
  return !!userdata;
};

const logout = () => {
  localStorage.removeItem('access_token');
  window.location.href = '/';
};

const getUserId = () => {
  if (!userId && localStorage.access_token) {
    const payload = jsonwebtoken.decode(localStorage.access_token);
    userId = payload.id;
  }
  return userId;
};

function accessType() {
  const accessMap = {
    'ebcdf8b5-ede5-4737-b6c9-f9a857fee5d3': 'full', // @jeff
    'be03853c-6190-4c6f-bdf5-912253d9b90d': 'full', // @janakan
    '21d13dc4-3a6a-4de5-8160-69e2d1360b16': 'full', // @vaxxchoice
    'e37fa54b-1452-4c2a-8697-1ffda16cf4e1': 'full', // @wgwebster
    '9acfd1c0-05d8-4c00-b252-0a6bafab76c1': 'full', // @CloutGoblin
    'f3afbac3-5c55-4f99-b630-0b7730434fac': 'full', // @@Mary97
    'ed3689cc-9b59-4c96-b289-5af78a8c21b5': 'full',
    '3954ee24-5dd1-4b0e-b29a-42bdd750fbc8': 'full',
    'a649b4dd-2472-4807-9e76-223074837da6': 'full', // john@magicfeatherinc.com
  };
  return accessMap[getUserId()];
}

function isAccessFull() {
  return accessType() === 'full';
}
function isSuper() {
  return getUserId() === 'fa44bb46-04e7-4b1c-af7f-786295fb2d9d';
}

function checkAuth() {
  // Logout if expired
  setInterval(() => {
    if (localStorage.access_token) {
      try {
        const payload = jsonwebtoken.decode(localStorage.access_token);
        const expiry = (payload.exp * 1000);
        if (Date.now() > expiry) {
          logout();
        }
      } catch (e) {
        logout();
      }
    }
  }, 1000);
}
checkAuth();

export default {
  isLoggedIn,
  setHeader,
  getUser,
  logout,
  getUserId,
  accessType,
  isAccessFull,
  isSuper,
};
