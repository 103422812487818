<template>
  <div id="app">
    <div>
      <b-navbar toggleable="lg" type="light" variant="light">
        <b-container>
          <b-navbar-brand :to="{name:'Home'}">CloutHub Admin</b-navbar-brand>

          <b-navbar-nav>
            <b-nav-item-dropdown text="Users">
              <b-nav-item :to="{name:'UserList'}">Find User</b-nav-item>
              <b-nav-item :to="{name:'SuspendedUserList'}">Suspended Users</b-nav-item>
              <b-nav-item v-if="fullAccess" :to="{name:'Verification'}">Verification Requests</b-nav-item>
              <b-nav-item :to="{name:'RecentUserList'}">Recently Joined</b-nav-item>
            </b-nav-item-dropdown>
            <b-nav-item-dropdown text="Blacklists">
              <b-nav-item :to="{name:'RestrictedUrlList'}">Blocked Domains</b-nav-item>
              <b-nav-item :to="{name:'RestrictedWordList'}">Blocked Words</b-nav-item>
              <b-nav-item :to="{name:'RestrictedEmailList'}">Blocked Email Domains</b-nav-item>
              <b-nav-item :to="{name:'AllowedEmailList'}">Allowed Email Domains</b-nav-item>
            </b-nav-item-dropdown>
            <b-nav-item-dropdown text="Reports" v-if="fullAccess">
              <b-nav-item :to="{name:'UserReports'}">Reported Users</b-nav-item>
              <b-nav-item v-if="superAccess" :to="{name:'PostReports'}">Reported Posts</b-nav-item>
            </b-nav-item-dropdown>
            <b-nav-item-dropdown text="Channels">
              <b-nav-item v-if="fullAccess" :to="{name:'Channels'}">Live Channels</b-nav-item>
              <b-nav-item v-if="fullAccess" :to="{name:'ManageChannels'}">Manage Channels</b-nav-item>
            </b-nav-item-dropdown>
            <b-nav-item :to="{name:'Moderation'}">Moderation</b-nav-item>
            <b-nav-item :to="{name:'Notifications'}">Notifications</b-nav-item>
            <b-nav-item-dropdown text="Meetings" v-if="fullAccess">
              <b-nav-item :to="{name:'MeetingEarnings'}">Meeting Earnings</b-nav-item>
              <b-nav-item :to="{name:'MeetingLivestream'}">Meeting Livestream</b-nav-item>
            </b-nav-item-dropdown>
            <b-nav-item :to="{name:'FeaturedPosts'}">Featured Posts</b-nav-item>
            <b-nav-item :to="{name:'VideoPosts'}">Video Posts</b-nav-item>
            <b-nav-item :to="{name:'AdSlots'}">Ad Slots</b-nav-item>
            <b-nav-item :to="{name:'SponsoredAreas'}">Sponsored Areas</b-nav-item>
          </b-navbar-nav>

          <b-navbar-nav class="ml-auto">
            <b-nav-item-dropdown right>
              <!-- Using 'button-content' slot -->
              <template #button-content>
                <span v-if="user">{{user.username}}</span>
              </template>
              <b-dropdown-item @click="signOut()">Sign Out</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>

        </b-container>
      </b-navbar>
    </div>
    <router-view/>
  </div>
</template>
<script>
import $auth from '@/services/auth';
import GetUser from '@/gql/users/GetUser.gql';

export default {
  computed: {
    superAccess() {
      return $auth.isSuper();
    },
    fullAccess() {
      return $auth.accessType() === 'full';
    },
  },
  apollo: {
    user: {
      query: GetUser,
      variables() {
        return {
          id: $auth.getUserId(),
        };
      },
    },
  },
  methods: {
    signOut() {
      localStorage.clear();
      window.location.href = '/';
    },
  },
};
</script>
