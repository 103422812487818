<template>
  <div>
    <b-container>
      <app-page-header title="Manage Channels">
        <b-form>
          <b-button variant="primary" @click="select()">+ Create Category</b-button>
        </b-form>
      </app-page-header>
      <template>
        <!-- <b-table hover :items="users" :fields="fields" @row-clicked="select" :filter="query" show-empty :busy="$apollo.queries.users.loading">
          <template #table-busy>
            <b-spinner />
          </template>
          <template #head(channelTrueNews)>
            <span>True News</span>
          </template>
          <template #cell(channelTrueNews)="data">
            <span>{{ data.item.channelTrueNews ? 'Yes' : 'No' }}</span>
          </template>
          <template #empty>No live channels</template>
          <template #emptyfiltered>No matching live channels</template>
        </b-table> -->
        <draggable v-model="categoriesList" @change="updateCategories">
            <transition-group>
                <div v-for="element in categoriesList" :key="element.id" class="categories-list" @click="select(element)">
                    <img :src="element.img" alt="icon" class="category-img">
                    {{element.label}}
                </div>
            </transition-group>
        </draggable>
        <b-modal ref="CreateCategoryModal" :title="isEdit ? 'Update Category': 'Add Category'" @ok="addCategories()">
          <b-form-group>
            <b-form-input v-model="category.label" placeholder="Category Label" class="my-2"></b-form-input>
            <b-form-input v-model="category.value" placeholder="Category Value" class="my-2"></b-form-input>
            <b-form-input v-model="category.img" placeholder="Image Url" class="my-2"></b-form-input>
            <b-form-input v-model="category.position" placeholder="Position" class="my-2" v-if="!isEdit"></b-form-input>
          </b-form-group>
        </b-modal>
      </template>
    </b-container>
  </div>
</template>
<script>
import AppPageHeader from '@/components/PageHeader.vue';
import ListCategories from '@/gql/channels/ListCategories.gql';
import AddPostVideoCat from '@/gql/channels/AddPostVideoCat.gql';
import UpdatePostVideoCat from '@/gql/channels/UpdatePostVideoCat.gql';
import draggable from 'vuedraggable';

export default {
  components: {
    AppPageHeader,
    draggable,
  },

  data() {
    return {
      isEdit: false,
      category: {
        label: '',
        value: '',
        img: '',
        position: '',
      },
      categoriesList: [],
      query: '',
      fields: [
        'categoryName',
      ],
    };
  },
  mounted() {
    this.getCategories();
  },
  methods: {
    async updateCategories() {
      console.log(this.categoriesList);
      const categoriesList = this.categoriesList.map((val, index) => ({ ...val, position: index + 1 }));
      categoriesList.forEach(async (val) => {
        await this.$apollo.mutate({
          mutation: UpdatePostVideoCat,
          variables: {
            ...val,
          },
        });
      });
    },
    async addCategories() {
      if (!this.category.label.trim() || !this.category.value.trim()) {
        alert('Please fill label & value of category');
        return;
      }
      console.log(this.category);
      try {
        if (!this.isEdit) {
          await this.$apollo.mutate({
            mutation: AddPostVideoCat,
            variables: {
              ...this.category,
              position: Number(this.category.position),
            },
          });
        } else {
          await this.$apollo.mutate({
            mutation: UpdatePostVideoCat,
            variables: {
              ...this.category,
              position: Number(this.category.position),
            },
          });
        }
        this.getCategories();
      } catch (err) {
        console.log(err);
      }
    },
    async getCategories() {
      const { data } = await this.$apollo.query({
        query: ListCategories,
      });
      this.categoriesList = data.list_post_video_categories;
    },
    select(row) {
      if (row) {
        this.isEdit = true;
        this.category = { ...row };
      } else {
        this.isEdit = false;
        this.category = {
          label: '',
          value: '',
          img: '',
          position: '',
        };
      }
      this.$refs.CreateCategoryModal.show();
    },

  },
};
</script>
