<template>
  <b-card header="Content Warning Customization">
    <div v-if="fullAccess">
      <b-input v-model="message" placeholder="Warning alert for violent contents" class="mb-4"/>
      <b-button v-if="!user.enabledWarningAlert" variant="outline-primary" size="small" block @click="setWarningMessage(true)">Set Warning Alert</b-button>
      <b-button v-if="user.enabledWarningAlert" variant="outline-danger" size="small" block @click="setWarningMessage(false)">Revoke Warning Alert</b-button>
    </div>
  </b-card>
</template>

<script>
import $auth from '@/services/auth';
import SetAlertUser from '@/gql/users/SetAlertUser.gql';

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      message: '',
    };
  },
  computed: {
    fullAccess() {
      return $auth.accessType() === 'full';
    },
  },
  mounted() {
    this.message = this.user.warning_msg;
  },
  methods: {
    async setWarningMessage(enable) {
      console.log(enable);
      await this.$apollo.mutate({
        mutation: SetAlertUser,
        variables: {
          userId: this.user.id,
          warningMsg: this.message,
        },
      });
      this.$emit('refresh');
    },
  },
};
</script>
