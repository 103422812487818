<template>
  <div>
    <b-container>
      <b-row>
        <b-col md="6" offset="3">
          <img src="@/assets/clouthub-logo.png" class="brand mt-5">
          <b-spinner v-if="loading" />
          <b-form @submit.prevent="login()" class="login-area" v-if="!loading">
            <b-form-group label="Email" class="mt-3">
              <b-input type="text" v-model="email"/>
            </b-form-group>
            <b-form-group label="Password" class="mt-3">
              <b-input type="password" v-model="password"/>
            </b-form-group>
            <b-button type="submit" class="mt-3" variant="primary">Login</b-button>
            <p>V0.1</p>
          </b-form>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Login from '@/gql/general/Login.gql';

export default {
  data() {
    return {
      email: '',
      password: '',
      loading: false,
    };
  },
  methods: {
    async login() {
      this.errMsg = null;
      if (this.email && this.password) {
        if (this.rememberUser) {
          localStorage.email = this.email;
        }
        try {
          this.loading = true;
          const resp = await this.$apollo.query({
            query: Login,
            variables: {
              email: this.email,
              password: this.password.trim(),
            },
          });
          localStorage.access_token = resp.data.token;
          window.location.href = '/';
        } catch (err) {
          this.$bvModal.msgBoxOk(err.message);
          this.loading = false;
        }
      }
    },
  },
};
</script>
<style>
.brand
{
  width: 200px;
  display: block;
  margin-bottom: 20px;
}
.login-area
{
  border: solid 1px #ccc;
  padding: 20px;
}
</style>
