<template>
  <b-container>
    <app-page-header title="Verification Requests"/>
    <b-spinner v-if="finding" />
    <vue-good-table
        v-if="users.length"
        :columns="fields"
        :rows="users">
      <div slot="emptystate">
        No verification requests pending.
      </div>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'actions'">
          <app-verify-user-button :user="props.row" @refresh="refresh" />
        </span>
      </template>
    </vue-good-table>
  </b-container>
</template>
<script>
import AppPageHeader from '@/components/PageHeader.vue';
import AppVerifyUserButton from '@/components/users/VerifyUserButton.vue';
import ListVerificationTasks from '@/gql/verification/ListVerificationTasks.gql';

export default {
  components: {
    AppPageHeader,
    AppVerifyUserButton,
  },
  data() {
    return {
      finding: false,
      fields: [
        {
          label: 'User Name',
          field: 'username',
        },
        {
          label: 'First Name',
          field: 'firstname',
        },
        {
          label: 'Last Name',
          field: 'lastname',
        },
        {
          label: 'Display Name',
          field: 'displayname',
        },
        {
          label: 'Requested Badge Type',
          field: 'requestedBadgeType',
        },
        {
          label: 'Badge Type',
          field: 'badgeType',
        },
        {
          label: 'Actions',
          field: 'actions',
        },
      ],
      user: null,
      users: [],
    };
  },
  mounted() {
    this.findUsers();
  },
  methods: {
    async findUsers() {
      this.finding = true;
      const resp = await this.$apollo.query({
        query: ListVerificationTasks,
      });
      this.finding = false;
      this.users = resp.data.users;
    },
    refresh() {
      console.log('Refresh');
      this.findUsers();
    },
  },
};
</script>
