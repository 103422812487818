<template>
  <b-container>
    <app-page-header title="Reported Users">
      <b-button v-router-link="{name:'UserReportsWizard'}">Wizard Mode</b-button>
    </app-page-header>
    <b-spinner v-if="finding" />
    <vue-good-table
        v-if="reports.length"
        :columns="fields"
        @on-row-click="select"
        :rows="reports">
      <div slot="emptystate">
        No user complaints left
      </div>
      <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'latest_report'">
            {{ props.row.latest_report | moment('LLL') }}
          </span>
        </template>
    </vue-good-table>
    <!-- <b-table hover :items="reports" :fields="fields" @row-clicked="select" :busy="$apollo.queries.reports.loading">
      <template #busy>
        <b-spinner />
      </template>
      <template #empty>No user complaints left</template>
      <template #cell(user)="data">
        <p>{{data.item.user.username}}</p>
      </template>
    </b-table> -->
  </b-container>
</template>
<script>
import ListUserReports from '@/gql/reports/ListUserReports.gql';
import AppPageHeader from '@/components/PageHeader.vue';

export default {
  components: {
    AppPageHeader,
  },
  data() {
    return {
      reports: [],
      finding: false,
      fields: [
        {
          label: 'User Name',
          field: 'user.username',
        },
        {
          label: 'Total Reports',
          field: 'total_reports',
        },
        {
          label: 'Latest Report',
          field: 'latest_report',
        },
      ],
    };
  },
  mounted() {
    this.findUsers();
  },
  methods: {
    async findUsers() {
      this.finding = true;
      const resp = await this.$apollo.query({
        query: ListUserReports,
      });
      this.finding = false;
      this.reports = resp.data.reports;
    },
    select(report) {
      this.$router.push({
        name: 'UserDetail',
        params: report.row.user,
      });
    },
  },
};
</script>
