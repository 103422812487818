<template>
  <div>
    <b-container>
      <app-page-header title="Ad Slots">
        <!-- <b-form>
          <b-input v-model="query" />
        </b-form> -->
        <b-button @click="openAddModal()" variant="primary">Add Ad Slot</b-button>
      </app-page-header>
      <template>
        <!-- Edit Model -->
        <vue-good-table v-if="!finding" :columns="tableFields" :rows="data">
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'zoneId'">
              {{ props.row.zoneId }}
            </span>
            <span v-if="props.column.field == 'postId'">
              {{ props.row.postId }}
            </span>
            <span v-if="props.column.field == 'status'">
              {{ props.row.status }}
            </span>
            <span v-if="props.column.field == 'action'" class="action-box">
              <b-button @click="openEditModal(props.row)" variant="primary">Edit</b-button>
              <!-- <b-button @click="openDeleteModal(props.row)" variant="danger">Delete</b-button> -->
            </span>

          </template>
        </vue-good-table>
        <b-modal ref="AddEditFeaturedPostModal" @ok="AddUpdateFeaturedPost()" @cancel="closeEditModal()">
          <b-form-group label="Post Id">
            <b-form-input v-model="draftItem.postId"></b-form-input>
          </b-form-group>
          <b-form-group label="Zone Id">
            <b-form-input v-model="draftItem.zoneId"></b-form-input>
          </b-form-group>
          <b-form-group label="Status">
            <b-form-checkbox v-model="draftItem.status" switch></b-form-checkbox>
          </b-form-group>
        </b-modal>

        <b-modal ref="deleteModal" @ok="deleteItem" @cancel="closeDeleteModal()">
          Are you sure you want to delete this item?
        </b-modal>
      </template>
    </b-container>
  </div>
</template>
<script>
import AppPageHeader from '@/components/PageHeader.vue';
import getAllAdSlots from '@/gql/general/getAllAdSlots.gql';
import addAdSlot from '@/gql/general/addAdSlot.gql';
import updateAdSlot from '@/gql/general/updateAdSlot.gql';

export default {
  components: {
    AppPageHeader,
  },
  data() {
    return {
      finding: false,
      data: [],
      query: '',
      isEditModalOpen: false,
      draftItem: {
        id: '',
        postId: '',
        zoneId: '',
        status: true,
      },
      tableFields: [
        {
          label: 'zone ID',
          field: 'zoneId',
        },
        {
          label: 'Post Id',
          field: 'postId',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      formType: 'edit',
    };
  },
  mounted() {
    this.getVideosSlots();
  },
  methods: {
    openDeleteModal(item) {
      this.itemToDelete = item;
      this.$refs.deleteModal.show();
    },

    async deleteItem() {
      const itemId = this.itemToDelete.uuid; // Adjust according to your data structure
      try {
        // Perform deletion logic here
        // Call the mutation to delete the item
        // Example:
        // await this.$apollo.mutate({
        //   mutation: deleteAdSlot,
        //   variables: { id: itemId },
        // });
        console.log('Item deleted:', itemId);
        // Refresh data after deletion
        await this.getVideosSlots();
      } catch (error) {
        console.error('Error deleting item:', error);
      }
      // Close the delete modal
      this.closeDeleteModal();
    },

    closeDeleteModal() {
      this.$refs.deleteModal.hide();
    },

    async getVideosSlots() {
      try {
        this.finding = true;
        const resp = await this.$apollo.query({
          query: getAllAdSlots,
          variables: {},
        });
        this.finding = false;
        this.data = resp.data.get_all_ad_slots;
      } catch (err) {
        this.finding = false;
        console.log(err);
      }
    },

    openAddModal() {
      this.draftItem = {
        postId: '',
        zoneId: '',
        status: true,
      };
      this.formType = 'add';
      this.$refs.AddEditFeaturedPostModal.show();
    },

    openEditModal(item) {
      this.formType = 'edit';
      this.draftItem.uuid = item.uuid;
      this.draftItem.zoneId = item.zoneId;
      this.draftItem.postId = item.postId;
      this.draftItem.status = item.status;

      this.$refs.AddEditFeaturedPostModal.show();
    },

    async AddUpdateFeaturedPost() {
      // variables
      const variables = {
        zoneId: parseFloat(this.draftItem.zoneId),
        postId: this.draftItem.postId?.toString(),
        status: `${this.draftItem.status}`,
      };

      if (this.formType === 'add') {
        await this.$apollo.mutate({
          mutation: addAdSlot,
          variables,
        });
      } else if (this.formType === 'edit') {
        variables.uuid = this.draftItem.uuid;
        await this.$apollo.mutate({
          mutation: updateAdSlot,
          variables,
        });
      }
      await this.getVideosSlots();
      this.closeEditModal();
    },

    closeEditModal() {
      this.isEditModalOpen = false;
    },
  },
};
</script>

<style>
.datetime-input > div:first-of-type {
  display: flex;
  gap: 10px;
}

.action-box {
  display: flex;
  gap: 10px;
}
</style>
