<template>
  <div>
    <b-card header="Status">
      <div class="text-center">
        <b-icon icon="check-circle" class="account-status-icon" v-if="!user.suspended"></b-icon>
        <b-icon icon="shield-exclamation" class="account-status-icon suspended" v-if="user.suspended"></b-icon>
        <div v-if="!user.suspended">Account Active</div>
        <div v-if="user.suspended">Account Suspended</div>
        <b-button variant="outline-danger" v-if="!user.suspended" @click="suspendUser()" block class="mt-3">Suspend Account</b-button>
        <b-button variant="outline-success" v-if="user.suspended" @click="unsuspendUser()" block class="mt-3">Unsuspend Account</b-button>
        <b-button variant="outline-danger" v-if="!isDeleted" @click="deleteUser()" block class="mt-3">Delete Account</b-button>
        <b-button variant="outline-danger" v-if="isDeleted" block class="mt-3">Account Deleted</b-button>

        <div v-if="user.suspension">
          Suspended {{user.suspension.createdAt | moment('from')}} for {{user.suspension.reason || 'unknown reasons'}} by
          <span v-if="user.suspension.admin"> {{user.suspension.admin.displayname}}</span>
          <span v-if="!user.suspension.admin"> unknown admin</span>
        </div>
      </div>
    </b-card>
    <b-modal ref="deleteModal" ok-only @ok="doDelete()" title="Delete User">
      <div>Are you sure you want to delete user</div>
    </b-modal>
    <b-modal ref="suspensionModal" ok-only @ok="doSuspend()" title="Suspend User">
      <div>Please provide a reason for internal records:</div>
      <b-select v-model="suspensionReason">
        <b-select-option value="Spam">Spam</b-select-option>
        <b-select-option value="SexualContent">Sexual Content</b-select-option>
        <b-select-option value="Threats">Threats</b-select-option>
        <b-select-option value="Fraud">Fraud/Scam</b-select-option>
        <b-select-option value="FakeAccount">Fake Account</b-select-option>
        <b-select-option value="Other">Other</b-select-option>
      </b-select>
    </b-modal>
  </div>
</template>

<script>
import SuspendUser from '@/gql/suspendeds/SuspendUser.gql';
import DeleteUser from '@/gql/suspendeds/DeleteUser.gql';
import UnsuspendUser from '@/gql/suspendeds/UnsuspendUser.gql';

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isDeleted: false,
      suspensionReason: null,
    };
  },
  methods: {
    async deleteUser() {
      this.$refs.deleteModal.show();
    },
    async suspendUser() {
      this.$refs.suspensionModal.show();
    },
    async doSuspend() {
      console.log('Do Suspend');
      const reason = this.suspensionReason;
      if (reason) {
        await this.$apollo.mutate({
          mutation: SuspendUser,
          variables: {
            id: this.user.id,
            reason,
          },
        });
        this.$emit('refresh');
      }
    },
    async doDelete() {
      console.log('Do Delete');
      await this.$apollo.mutate({
        mutation: DeleteUser,
        variables: {
          id: this.user.id,
        },
      });
      this.isDeleted = true;
      this.$router.push({
        name: 'UserList',
      });
      this.$emit('refresh');
    },
    async unsuspendUser() {
      if (confirm('Are you sure you want to unsuspend this user?')) {
        await this.$apollo.mutate({
          mutation: UnsuspendUser,
          variables: {
            id: this.user.id,
          },
        });
        this.$emit('refresh');
      }
    },
  },
};
</script>

<style scoped>
  .account-status-icon
  {
    width: 50px;
    height: 50px;
    color: green;
  }
  .account-status-icon.suspended
  {
    color: red
  }
</style>
