<template>
  <div>
    <b-form-group label="Status*">
      <b-select v-model="draftNotification.status">
        <b-select-option :value="'active'">Active</b-select-option>
        <b-select-option :value="'deactivated'">Non Active</b-select-option>
      </b-select>
    </b-form-group>
    <b-form-group label="Type*">
      <b-select v-model="draftNotification.typeNotification">
        <b-select-option :value="'update'">Update</b-select-option>
        <b-select-option :value="'alert'">Alert</b-select-option>
        <b-select-option :value="'message'">Message</b-select-option>
        <b-select-option :value="'warning'">Warning</b-select-option>
      </b-select>
    </b-form-group>
    <b-form-group label="Message*">
      <app-textarea
        id="create_notification_body"
        ref="composer"
        v-model="draftNotification.body"
        :maxlength="8000"
        placeholder="Notification Message..."
        class="cs-textstyle-paragraph"
        rows="1"
      />
      <div class="body__chars cs-textstyle-paragraph-small">
        {{ draftNotification.body ? draftNotification.body.length : 0 }}/8000 Characters
      </div>
    </b-form-group>
    <b-form-group label="Url">
      <b-form-input v-model="draftNotification.url" placeholder="Url"></b-form-input>
    </b-form-group>
  </div>
</template>
<script>
import AppTextarea from '@/components/general/Textarea.vue';

export default {
  components: {
    AppTextarea,
  },
  props: {
    draftNotification: {
      type: Object,
      required: false,
    },
  },
  mounted() {
    if (!this.draftNotification) {
      this.draftNotification = {
        status: '',
        typeNotification: '',
        url: '',
        body: '',
      };
    }
  },
  methods: {},
};
</script>

<style scoped>
.body__chars {
  margin-top: 3px;
  text-align: right;
  color: var(--cs-gray-04);
}
</style>
