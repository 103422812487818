<template>
  <div>
    <b-container>
      <app-page-header title="Recently Joined"></app-page-header>
      <b-spinner v-if="finding" />
      <vue-good-table
        v-if="!finding"
        :columns="tableFields"
        @on-row-click="selectUser"
        :rows="users">
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'username'">
            {{ props.row.username }}
          </span>
          <span v-if="props.column.field == 'firstname'">
            {{ props.row.firstname }}
          </span>
          <span v-if="props.column.field == 'lastname'">
            {{ props.row.lastname }}
          </span>
          <span v-if="props.column.field == 'displayname'">
            {{ props.row.displayname }}
          </span>
          <span v-if="props.column.field == 'email'">
            {{ props.row.email }}
          </span>
          <span v-if="props.column.field == 'createdAt'">
            {{ props.row.createdAt | moment('from') }}
          </span>
          <span v-if="props.column.field == 'suspended'">
            <app-suspend-user-button :user="props.row" />
          </span>
        </template>
      </vue-good-table>
    </b-container>
  </div>
</template>
<script>
import AppPageHeader from '@/components/PageHeader.vue';
import ListRecentUsers from '@/gql/users/ListRecentUsers.gql';
import AppSuspendUserButton from '@/components/users/SuspendUserButton.vue';

export default {
  components: {
    AppPageHeader,
    AppSuspendUserButton,
  },
  data() {
    return {
      users: [],
      filterKey: 'username',
      filterValue: null,
      finding: false,
      tableFields: [
        {
          label: 'User Name',
          field: 'username',
        },
        {
          label: 'CreatedAt',
          field: 'createdAt',
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'Display Name',
          field: 'displayname',
        },
        {
          label: 'First Name',
          field: 'firstname',
        },
        {
          label: 'Last Name',
          field: 'lastname',
        },
        {
          label: 'Suspended',
          field: 'suspended',
        },
      ],
    };
  },
  computed: {
    selectedUsers() {
      return this.users && this.users.filter((user) => user.selected);
    },
  },
  mounted() {
    this.findListRecentUsers();
  },
  methods: {
    async findListRecentUsers() {
      this.finding = true;
      const result = await this.$apollo.query({
        query: ListRecentUsers,
        update(resp) {
          const output = resp.users.map((user) => {
            const newUser = { ...user };
            newUser.suspended = newUser.suspended || false;
            newUser.selected = false;
            return newUser;
          });
          return output;
        },
      });
      this.finding = false;
      this.users = result.data.users;
    },
    selectUser(user) {
      this.$router.push({
        name: 'UserDetail',
        params: user.row,
      });
    },
  },
};
</script>
