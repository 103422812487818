<template>
  <b-container>
  <b-row>
    <b-col>
      <b-card header="TOTAL NUMBER OF USERS IN SYSTEM" class="mb-3 mt-2">
        <div class="text-center">{{analytics.totalNumberOfUsersInSystem}}</div>
      </b-card>
      <b-card header="NEW SIGN UPS" class="mb-3">
        <div class="text-center">{{analytics.newSignUps}}</div>
      </b-card>
      <b-card header="ACCOUNTS SUSPENDED" class="mb-3">
        <div class="text-center">{{analytics.accountsSuspended}}</div>
      </b-card>
      <b-card header="MODERATIONS OPEN" class="mb-3">
        <div class="text-center">{{analytics.moderationsOpen}}</div>
      </b-card>
    </b-col>
     <b-col>
      <b-card header="TOTAL LIVE STREAMS" class="mb-3 mt-2">
        <div class="text-center">{{analytics.totalLiveStreams}}</div>
      </b-card>
      <b-card header="MOST ENGAGED POST" class="mb-3">
        <div class="text-center">{{analytics.mostEngagedPost}}</div>
      </b-card>
      <b-card header="USER WITH MOST FOLLOWERS" class="mb-3">
        <div class="text-center">{{analytics.userWithMostFollowers}}</div>
      </b-card>
    </b-col>
     <b-col>
      <b-card header="USER WITH MOST FRIENDS" class="mb-3 mt-2">
        <div class="text-center">{{analytics.userWithMostFriends}}</div>
      </b-card>
      <b-card header="USER WITH MOST POSTS" class="mb-3">
        <div class="text-center">{{analytics.userWithMostPosts}}</div>
      </b-card>
      <b-card header="USER WITH MOST COMMENTS" class="mb-3">
        <div class="text-center">{{analytics.userWithMostComments}}</div>
      </b-card>
    </b-col>
  </b-row>
  </b-container>
</template>

<script>
import ListAnalytics from '@/gql/users/ListAnalytics.gql';

export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      analytics: {},
    };
  },
  mounted() {
    this.getAnalytics();
  },
  methods: {
    async getAnalytics() {
      const response = await this.$apollo.query({
        query: ListAnalytics,
      });
      this.analytics = response.data.list_analytics;
      console.log(response);
    },
  },
};
</script>
