<template>
  <b-card header="Unlimited Paid">
    <div v-if="fullAccess">
      <b-button v-if="user.isvipuser" variant="outline-danger" size="small" block @click="setVIPUser(false)">Revoke Unlimited Paid</b-button>
    </div>
    <div v-if="fullAccess">
      <b-button v-if="!user.isvipuser" variant="outline-primary" size="small" block @click="setVIPUser(true)">Enable Unlimited Paid</b-button>
    </div>
  </b-card>
</template>

<script>
import SetVIPUser from '@/gql/users/SetVIPUser.gql';
import $auth from '@/services/auth';

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    fullAccess() {
      return $auth.accessType() === 'full';
    },
  },
  mounted() {
    // this.isVIPUser();
  },
  methods: {
    async setVIPUser(isVIP) {
      await this.$apollo.mutate({
        mutation: SetVIPUser,
        variables: {
          email: this.user.email,
          isVIP,
        },
      });
      this.$emit('refresh');
    },
  },
};
</script>
